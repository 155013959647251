body {
  text-align: center;
  background: black;
  color: white;
  font-size: 100%;
  padding: 1em;
  margin-bottom: 2em;
  background-image: url("/my-back-mobile.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 768px) {
  body {
    background-image: url("/my-back-desktop.jpg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

a {
  color: red;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.photo {
  margin-bottom: 1em;
  border: 3px solid #bf960a;
  margin-top: 1em;
  border-radius: 3px;
}

.sharing-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.sharing-ul li {
  margin-left: 1rem;
  margin-right: 1rem;
}

.download {
  margin-top: 1em;
  font-size: 1.5em;
  text-transform: none;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.download svg {
  margin-right: 0.5em;
  font-size: 2.5rem;
}

h1 {
  display: none;
}

h2 {
  color: white;
  font-weight: bold;
}
